import { Module } from 'vuex';
import { MyDropsState } from '@/interfaces/store/my_drops';
import { RootState } from '@/interfaces/store/shared';
import { BasicError, Drop } from '@/interfaces/models';
import axios from 'axios';
import { Api } from '@/services';

const module: Module<MyDropsState, RootState> = {
  state: {
    drops: null,
    error: {
      isError: false,
      message: null,
    },
  },
  mutations: {
    setMyDropsError(state, message: string) {
      state.error.isError = true;
      state.error.message = message;
    },
    clearMyDropsError(state) {
      state.error.isError = false;
      state.error.message = null;
    },
    setMyDrops(state, drops: Drop[]) {
      state.drops = drops;
    },
    clearMyDrops(state) {
      state.drops = null;
    },
    removeFromMyDrops(state, dropId: string) {
      const index = state.drops?.findIndex((d) => d.id === dropId);
      if (index === undefined || index === -1) return;

      state.drops?.splice(index, 1);
    },
  },
  actions: {
    setMessageForMyDropsError(
      { commit },
      { error, defaultMsg }: { error: Error; defaultMsg: string; },
    ) {
      if (axios.isAxiosError(error)) {
        if (error.response !== undefined && error.response.data.reason !== undefined) {
          commit('setMyDropsError', error.response.data.reason);
        } else {
          commit('setMyDropsError', defaultMsg);
        }
      } else {
        commit('setMyDropsError', defaultMsg);
      }
    },
    async loadMyDrops({ commit, dispatch }) {
      commit('clearMyDropsError');
      commit('clearMyDrops');
      try {
        const res = await Api.fetchUserDrops();
        commit('setMyDrops', res.data);
      } catch (e) {
        dispatch('setMessageForMyDropsError', e);
      }
    },
    async deleteFromMyDrops({ commit, state, dispatch }, dropId: string) {
      try {
        await Api.deleteDrop(dropId);

        commit('removeFromMyDrops', dropId);
        dispatch('pushSuccessNotification', 'Drop successfully deleted');
      } catch (error) {
        if (error?.response?.data !== undefined) {
          const response = error.response.data as BasicError;
          dispatch('pushErrorNotification', `Failed to delete drop. ${response.reason}`);
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred, failed to delete drop');
        }
      }
    },
  },
};

export default module;
