import { Module } from 'vuex';
import {
  NewNotification,
  Notification,
  NotificationState,
  NotificationType,
} from '@/interfaces/store/notifications';
import { RootState } from '@/interfaces/store/shared';

const module: Module<NotificationState, RootState> = {
  state: {
    nextId: 0,
    notifications: [],
  },
  mutations: {
    pushNotification(state, notification: Notification) {
      state.notifications.push(notification);
    },
    removeNotification(state, index: number) {
      state.notifications.splice(index, 1);
    },
    clearNotification(state) {
      state.notifications = [];
    },
    incrementId(state) {
      state.nextId += 1;
    },
  },
  actions: {
    pushNotification({ state, commit }, notification: NewNotification) {
      const expiryDate = new Date();
      const time = notification.time ?? 5;
      expiryDate.setSeconds(expiryDate.getSeconds() + time);

      const newNotification = {
        id: state.nextId,
        title: notification.title,
        content: notification.content,
        type: notification.type ?? NotificationType.Info,
        expiryDate,
      };

      commit('incrementId');
      commit('pushNotification', newNotification);
    },
    pushErrorNotification({ dispatch }, text: string) {
      dispatch('pushNotification', {
        title: 'Error',
        content: text,
        type: NotificationType.Error,
      });
    },
    pushSuccessNotification({ dispatch }, text: string) {
      dispatch('pushNotification', {
        title: 'Success',
        content: text,
        type: NotificationType.Success,
      });
    },
    updateNotifications({ state, commit }) {
      state.notifications.forEach((notification, index) => {
        const currentDate = new Date();
        if (notification.expiryDate < currentDate) {
          commit('removeNotification', index);
        }
      });
    },
    removeNotificationById({ state, commit }, id: number) {
      const index = state.notifications.findIndex((n) => n.id === id);
      if (index === -1) return;

      commit('removeNotification', index);
    },
  },
};

export default module;
