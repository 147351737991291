<template>
  <button :class="buttonStyle">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonListItem',
  props: {
    danger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonStyle() {
      return this.$props.danger
        ? `text-red-500 w-full py-1 text-lg first:border-t border-dark-purple-l
           border-b hover:bg-red-800 hover:bg-opacity-70 hover:text-white
           active:text-white active:bg-red-900 active:bg-opacity-70 transition-colors`
        : `text-white w-full py-1 text-lg first:border-t border-dark-purple-l
           border-b hover:bg-violet-800 hover:bg-opacity-50 active:bg-violet-900
           active:bg-opacity-50 transition-colors`;
    },
  },
};
</script>

<style scoped>

</style>
