import { Module } from 'vuex';
import { RootState } from '@/interfaces/store/shared';
import { UserState } from '@/interfaces/store/user';
import { BasicError, User, UserTokenResponse } from '@/interfaces/models';
import { Api } from '@/services';
import axios from 'axios';
import router from '@/router';

const module: Module<UserState, RootState> = {
  state: {
    currentUser: null,
    token: null,
    refreshToken: null,
    isLoading: false,
    error: {
      isError: false,
      message: null,
    },
  },
  mutations: {
    setUser(state, user: User) {
      state.currentUser = user;
    },
    setTokens(state, { token, refreshToken }: UserTokenResponse) {
      state.token = token;
      state.refreshToken = refreshToken;
    },
    clearTokens(state) {
      state.token = null;
      state.refreshToken = null;
    },
    logout(state) {
      state.currentUser = null;
      state.token = null;
      state.refreshToken = null;
    },
    setUserError(state, message: string) {
      state.error.isError = true;
      state.error.message = message;
    },
    clearUserError(state) {
      state.error.isError = false;
      state.error.message = null;
    },
    setUserIsLoading(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    isLoggedIn(state): boolean {
      return state.currentUser !== null;
    },
  },
  actions: {
    setMessageForUserError(
      { commit },
      { error, defaultMsg }: { error: Error; defaultMsg: string; },
    ) {
      if (axios.isAxiosError(error)) {
        if (error.response !== undefined && error.response.data.reason !== undefined) {
          commit('setUserError', error.response.data.reason);
        } else {
          commit('setUserError', defaultMsg);
        }
      } else {
        commit('setUserError', defaultMsg);
      }
    },
    async authenticate(
      {
        commit,
        dispatch,
      },
      {
        username,
        password,
      }: { username: string; password: string },
    ) {
      commit('clearUserError');
      commit('setUserIsLoading', true);
      try {
        const res = await Api.authenticate(username, password);
        const user = {
          id: res.data.id,
          username: res.data.username,
          email: res.data.email,
          planId: res.data.planId,
          token: res.data.token,
          refreshToken: res.data.refreshToken,
        };
        commit('setUser', user);
        commit('setTokens', { token: user.token, refreshToken: user.refreshToken });
        axios.defaults.headers.common.authorization = `Bearer ${res.data.token}`;
        await dispatch('saveUser');
      } catch (error) {
        dispatch('setMessageForUserError', { error, defaultMsg: 'Error occurred while signing in' });
      }
      commit('setUserIsLoading', false);
    },
    async createUser(
      {
        commit,
        dispatch,
      },
      {
        username,
        email,
        password,
        verifyPassword,
      }: { username: string; email: string; password: string; verifyPassword: string },
    ) {
      commit('clearUserError');
      commit('setUserIsLoading', true);
      try {
        const res = await Api.createUser(username, email, password, verifyPassword);
        const user = {
          username: res.data.username,
          email: res.data.email,
          planId: res.data.planId,
        };
        commit('setUser', user);
        commit('setToken', res.data.token);
        axios.defaults.headers.common.authorization = `Bearer ${res.data.token}`;
        await dispatch('saveUser');
      } catch (error) {
        dispatch('setMessageForUserError', { error, defaultMsg: 'Error occurred while creating profile' });
      }
      commit('setUserIsLoading', false);
    },
    logout({ commit }) {
      commit('logout');
      delete axios.defaults.headers.common.authorization;
      localStorage.clear();
    },
    async updateUsername({ commit, dispatch }, username: string) {
      try {
        const res = await Api.patchUser({ username, email: null });
        commit('setUser', res.data);
      } catch (error) {
        if (error?.response?.data !== undefined) {
          const response = error.response.data as BasicError;
          dispatch('pushErrorNotification', `Failed to update username. ${response.reason}`);
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred, failed to update username');
        }
      }
    },
    async updateEmail({ commit, dispatch }, email: string) {
      try {
        const res = await Api.patchUser({ email, username: null });
        commit('setUser', res.data);
      } catch (error) {
        if (error?.response?.data !== undefined) {
          const response = error.response.data as BasicError;
          dispatch('pushErrorNotification', `Failed to update email. ${response.reason}`);
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred, failed to update email');
        }
      }
    },
    async deleteAccount({ commit, dispatch }) {
      try {
        await Api.deleteUser();
        delete axios.defaults.headers.common.authorization;
        localStorage.clear();
        commit('logout');
        await router.push('/');
      } catch (error) {
        if (error?.response?.data !== undefined) {
          const response = error.response.data as BasicError;
          dispatch('pushErrorNotification', `Failed to delete account. ${response.reason}`);
        } else {
          dispatch('pushErrorNotification', 'Unexpected error occurred, failed to delete account');
        }
      }
    },
    saveUser({ state }) {
      if (state.currentUser === null || state.token === null
        || state.refreshToken === null) throw new Error('Failed to save');

      const { id } = state.currentUser;
      localStorage.setItem('profile:userId', String(id));
      localStorage.setItem('profile:token', state.token);
      localStorage.setItem('profile:refreshToken', state.refreshToken);
    },
    async loadUser({ commit }) {
      const id = localStorage.getItem('profile:userId');
      const token = localStorage.getItem('profile:token');
      const refreshToken = localStorage.getItem('profile:refreshToken');

      if (id === null || token === null || refreshToken === null) {
        localStorage.clear();
        return;
      }

      try {
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
        commit('setTokens', { token, refreshToken });
        const res = await Api.fetchCurrentUserInfo();

        commit('setUser', res.data);
      } catch (e) {
        delete axios.defaults.headers.common.authorization;
        commit('clearTokens');
        localStorage.clear();
      }
    },
  },
};

export default module;
