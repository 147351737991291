import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Profile from '@/ui/views/profile/profile.vue';
import ResetPassword from '@/ui/views/profile/reset_password.vue';
import Home from '../ui/views/home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/profile',
    redirect: (to) => ({ path: '/profile/account' }),
  },
  {
    path: '/profile/account',
    name: 'accountSettings',
    component: Profile,
  },
  {
    path: '/profile/drops',
    name: 'myDrops',
    component: Profile,
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: ResetPassword,
  },
  {
    path: '/:dropId',
    name: 'drop',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
