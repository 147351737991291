<template>
  <div class="absolute bg-dark-purple-p top-10 right-0 z-10 rounded-lg shadow-md
              overflow-hidden bg-opacity-70 backdrop-blur-md">
    <ul class="flex flex-col z-30 items-stretch">
      <context-menu-item icon="fas fa-eye" @click="openPreview">Preview</context-menu-item>
      <context-menu-item icon="fas fa-trash-alt" :danger="true"
                         @click="deleteFile">Delete</context-menu-item>
    </ul>
  </div>
</template>

<script>
import ContextMenuItem from '@/ui/components/core/context_menus/context_menu_item.vue';

export default {
  name: 'DropFileContextMenu',
  components: { ContextMenuItem },
  props: {
    dropId: String,
    fileId: Number,
  },
  methods: {
    openPreview() {
      this.$store.dispatch('showFilePreview', this.$props.fileId);
    },
    deleteFile() {
      this.$store.dispatch('deleteFile', this.$props.fileId);
    },
  },
};
</script>

<style scoped>

</style>
