export enum NotificationType {
  Info,
  Success,
  Warning,
  Error
}

export interface NewNotification {
  title: string;
  content: string;
  type: NotificationType;
  time: number | undefined;
}

export interface Notification {
  id: number;
  title: string;
  content: string;
  expiryDate: Date;
  type: NotificationType;
}

export interface NotificationState {
  notifications: Notification[];
  nextId: number;
}
