import axios, { AxiosPromise, AxiosResponse } from 'axios';
import {
  AuthResponse,
  CreateDropResponse,
  Drop,
  DropFile, DropFileContent,
  FullDropInfo, NewDropFileRequest,
  PatchUserRequest, ResetPasswordLink, ResetPasswordRequest,
  User,
  UserTokenResponse,
} from '@/interfaces/models';

export default class {
  // User
  static authenticate(username: string, password: string): AxiosPromise<AuthResponse> {
    return axios.post('/auth/authenticate', { username, password });
  }

  static createUser(
    username: string,
    email: string,
    password: string,
    verifyPassword: string,
  ): AxiosPromise<AuthResponse> {
    return axios.post('/users', {
      username,
      email,
      password,
      verifyPassword,
    });
  }

  static fetchCurrentUserInfo(): AxiosPromise<User> {
    return axios.get('/me');
  }

  static patchUser(request: PatchUserRequest): AxiosPromise<User> {
    return axios.patch('/me', request);
  }

  static refreshTokens(refreshToken: string): AxiosPromise<UserTokenResponse> {
    console.log(`Sending refresh token ${refreshToken}`);
    return axios.post('/auth/refresh-tokens', null, {
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
    });
  }

  static deleteUser(): AxiosPromise {
    return axios.delete('/me');
  }

  static createResetPasswordLink(): AxiosPromise {
    return axios.post('/me/reset-password-links');
  }

  static getResetPasswordLink(token: string): AxiosPromise<ResetPasswordLink> {
    return axios.get(`/users/reset-password-links/${token}`);
  }

  static resetPassword(request: ResetPasswordRequest): AxiosPromise {
    return axios.patch('/users/password', request);
  }

  // Drops
  static createDrop(): AxiosPromise<CreateDropResponse> {
    return axios.post('/drops');
  }

  static async fetchUserDrops(): Promise<AxiosResponse<Drop[]>> {
    const res = await axios.get('/me/drops');
    res.data = res.data
      .map((x: any) => ({
        ...x,
        createDate: new Date(Date.parse(x.createDate)),
        expirationDate: new Date(Date.parse(x.expirationDate)),
      })) as Drop[];

    return res;
  }

  static fetchDropInfo(dropId: string): AxiosPromise<FullDropInfo> {
    return axios.get(`/drops/${dropId}`);
  }

  static deleteDrop(dropId: string, accessToken?: string): AxiosPromise<Drop> {
    const headers: Record<string, string> = accessToken !== undefined ? {
      'Temporary-Access-Token': accessToken,
    } : {};

    return axios.delete(`/drops/${dropId}`, {
      headers,
    });
  }

  // Files
  static uploadFile(
    dropId: string,
    file: File,
    onProgress: (progressEvent: any) => void,
    accessToken?: string,
  ): AxiosPromise<DropFile> {
    const formData = new FormData();
    formData.append('fileSize', file.size.toString());
    formData.append('file', file);

    const headers: Record<string, string> = accessToken !== undefined ? {
      'Content-Type': 'multipart/form-data',
      'Temporary-Access-Token': accessToken,
    } : {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(`/drops/${dropId}/files`, formData, {
      headers,
      onUploadProgress(progressEvent) {
        onProgress(progressEvent);
      },
    });
  }

  static fetchDropFileInfo(dropId: string, fileId: number): AxiosPromise<DropFile> {
    return axios.get(`/drops/${dropId}/files/${fileId}`);
  }

  static fetchDropFilePreview(dropId: string, fileId: number): AxiosPromise<DropFileContent> {
    return axios.get(`/drops/${dropId}/files/${fileId}/preview`);
  }

  static deleteDropFile(
    dropId: string,
    fileId: number,
    accessToken?: string,
  ): AxiosPromise<DropFile> {
    const headers: Record<string, string> = accessToken !== undefined ? {
      'Temporary-Access-Token': accessToken,
    } : {};

    return axios.delete(`/drops/${dropId}/files/${fileId}`, {
      headers,
    });
  }
}
